var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-1 mt-1"},[_c('h4',{staticClass:"mb-50"},[_vm._v(" "+_vm._s(_vm.$t('wizard.step-3.title'))+" ")]),_c('h5',{staticClass:"text-muted mb-1"},[_vm._v(" "+_vm._s(_vm.$t('wizard.step-3.subtitle'))+" ")]),_c('rc-overlay',{attrs:{"show":_vm.loading,"no-fade":"","variant":"white"}},[_c('validation-observer',{ref:"wizardStep3"},[_c('div',{staticClass:"mb-50"},[_c('validation-provider',{attrs:{"name":_vm.$t('services.form.base-information.name'),"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t('services.form.base-information.name'))+" ")]),_c('b-form-input',{attrs:{"id":"name","type":"text","minlength":"2","maxlength":"50","placeholder":_vm.$t('services.form.base-information.name'),"autocomplete":"off"},model:{value:(_vm.service.name),callback:function ($$v) {_vm.$set(_vm.service, "name", $$v)},expression:"service.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mb-50"},[_c('validation-provider',{attrs:{"name":_vm.$t('services.form.base-information.duration'),"rules":"required|min_value:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"duration"}},[_vm._v(" "+_vm._s(_vm.$t('services.form.base-information.duration'))+" ")]),_c('duration-selector',{attrs:{"id":"duration","max-hour":6},model:{value:(_vm.service.duration),callback:function ($$v) {_vm.$set(_vm.service, "duration", $$v)},expression:"service.duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mb-50"},[_c('b-row',{staticClass:"mb-50"},[_c('b-col',{staticClass:"mb-50",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('services.form.base-information.price-gross'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"priceGross"}},[_vm._v(" "+_vm._s(_vm.$t('services.form.base-information.price-gross'))+" ")]),_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":"zł"}},[_c('b-form-input',{staticClass:"text-right",attrs:{"id":"priceGross","type":"number","step":"0.01","value":"0.00","placeholder":"50.00","autocomplete":"off"},model:{value:(_vm.service.priceGross),callback:function ($$v) {_vm.$set(_vm.service, "priceGross", $$v)},expression:"service.priceGross"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('services.form.base-information.tax-percent'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"taxPercent"}},[_vm._v(" "+_vm._s(_vm.$t('services.form.base-information.tax-percent'))+" ")]),_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":"%"}},[_c('b-form-input',{staticClass:"text-right",attrs:{"id":"taxPercent","type":"number","placeholder":"23","autocomplete":"off"},model:{value:(_vm.service.taxPercent),callback:function ($$v) {_vm.$set(_vm.service, "taxPercent", $$v)},expression:"service.taxPercent"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('services.form.base-information.price-type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"priceGross"}},[_vm._v(" "+_vm._s(_vm.$t('shared.price-types.title'))+" ")]),_c('v-select',{attrs:{"id":"selfReservationType","options":_vm.priceTypes,"clearable":false,"searchable":false,"reduce":function (option) { return option.id; }},model:{value:(_vm.service.priceType),callback:function ($$v) {_vm.$set(_vm.service, "priceType", $$v)},expression:"service.priceType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)])],1),_c('wizard-footer',{attrs:{"is-last":"","disabled":_vm.processing || _vm.loading},on:{"submit":_vm.handleSubmit,"prev":function($event){return _vm.$emit('prev')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }