<template>
  <label>
    <slot />
    <rc-help :description="description" />
  </label>
</template>
<script>
import RcHelp from '@/layouts/components/rc/RcHelp'

export default {
  components: { RcHelp },
  props: {
    description: {
      type: [String, Object],
      default: null,
    },
  },
}
</script>
