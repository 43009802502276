var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('b-form-radio',{staticClass:"d-md-inline mr-0 mr-md-2 mb-50",attrs:{"value":true},model:{value:(_vm.isRegisteredCompany),callback:function ($$v) {_vm.isRegisteredCompany=$$v},expression:"isRegisteredCompany"}},[_vm._v(" "+_vm._s(_vm.$t('settings.address-form.is-company'))+" ")]),_c('b-form-radio',{staticClass:"d-md-inline mr-0 mr-md-2 mb-50",attrs:{"value":false},on:{"change":function($event){_vm.isRegisteredCompany === false ? _vm.value.taxIdentifier = null : null}},model:{value:(_vm.isRegisteredCompany),callback:function ($$v) {_vm.isRegisteredCompany=$$v},expression:"isRegisteredCompany"}},[_vm._v(" "+_vm._s(_vm.$t('settings.address-form.is-not-company'))+" ")])],1),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.isRegisteredCompany ? _vm.$t('settings.address-form.company-name') : _vm.$t('settings.address-form.name-and-surname'),"rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.isRegisteredCompany ? _vm.$t('settings.address-form.company-name') : _vm.$t('settings.address-form.name-and-surname'))+" ")]),_c('b-form-input',{attrs:{"id":"name","type":"text","minlength":"3","maxlength":"100","placeholder":_vm.isRegisteredCompany ? _vm.$t('settings.address-form.company-name') : _vm.$t('settings.address-form.name-and-surname'),"autocomplete":"chrome-off"},model:{value:(_vm.value.companyName),callback:function ($$v) {_vm.$set(_vm.value, "companyName", $$v)},expression:"value.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('settings.address-form.street-number'),"rules":((_vm.isRegisteredCompany ? 'required' : '') + "|min:3|max:100")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"addressLine1"}},[_vm._v(" "+_vm._s(_vm.$t('settings.address-form.street-number'))+" ")]),_c('b-form-input',{attrs:{"id":"addressLine1","type":"text","minlength":"3","maxlength":"100","placeholder":_vm.$t('settings.address-form.street-number'),"autocomplete":"chrome-off"},model:{value:(_vm.value.addressLine1),callback:function ($$v) {_vm.$set(_vm.value, "addressLine1", $$v)},expression:"value.addressLine1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t('settings.address-form.zip'),"rules":"required|min:3|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"text-nowrap",attrs:{"for":"zip"}},[_vm._v(" "+_vm._s(_vm.$t('settings.address-form.zip'))+" ")]),_c('b-form-input',{attrs:{"id":"zip","type":"text","minlength":"3","maxlength":"10","placeholder":_vm.$t('settings.address-form.zip'),"autocomplete":"chrome-off"},model:{value:(_vm.value.zipCode),callback:function ($$v) {_vm.$set(_vm.value, "zipCode", $$v)},expression:"value.zipCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"7"}},[_c('validation-provider',{attrs:{"name":_vm.$t('settings.address-form.city'),"rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"city"}},[_vm._v(" "+_vm._s(_vm.$t('settings.address-form.city'))+" ")]),_c('b-form-input',{attrs:{"id":"city","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('settings.address-form.city'),"autocomplete":"chrome-off"},model:{value:(_vm.value.city),callback:function ($$v) {_vm.$set(_vm.value, "city", $$v)},expression:"value.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.isRegisteredCompany)?_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('settings.address-form.tax-identifier'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"taxIdentifier"}},[_vm._v(" "+_vm._s(_vm.$t('settings.address-form.tax-identifier'))+" ")]),_c('b-form-input',{attrs:{"id":"taxIdentifier","type":"text","placeholder":_vm.$t('settings.address-form.tax-identifier-placeholder'),"autocomplete":"chrome-off"},model:{value:(_vm.value.taxIdentifier),callback:function ($$v) {_vm.$set(_vm.value, "taxIdentifier", $$v)},expression:"value.taxIdentifier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,291180424)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }