<template>
  <div class="mx-1 mt-1">
    <h4 class="mb-50">
      {{ $t('wizard.step-1.title') }}
    </h4>
    <h5 class="text-muted mb-1">
      {{ $t('wizard.step-1.subtitle') }}
    </h5>

    <rc-overlay
      :show="loading"
      no-fade
      variant="white"
    >
      <validation-observer ref="wizardStep1">

        <div class="mb-50">
          <validation-provider
            #default="{ errors }"
            :name="$t('settings.profile.short-name')"
            rules="required|min:3|max:32"
          >
            <rc-label
              for="short-name"
              :description="$t('settings.profile.short-name-description')"
            >
              {{ $t('settings.profile.short-name') }}
            </rc-label>
            <b-form-input
              id="short-name"
              v-model="organizationData.shortName"
              type="text"
              minlength="3"
              maxlength="32"
              :placeholder="$t('settings.profile.short-name-placeholder')"
              autocomplete="chrome-off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="mb-50">
          <validation-provider
            #default="{ errors }"
            :name="$t('settings.profile.contact-email')"
            rules="required|email"
          >
            <rc-label
              for="contact-email"
              :description="$t('settings.profile.contact-email-description')"
            >
              {{ $t('settings.profile.contact-email') }}
            </rc-label>
            <b-form-input
              id="contact-email"
              v-model="organizationData.email"
              type="email"
              :placeholder="$t('settings.profile.contact-email-placeholder')"
              autocomplete="chrome-off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="mb-50">
          <validation-provider
            #default="{ errors }"
            :name="$t('settings.profile.contact-phone')"
            rules="required"
          >
            <rc-label
              for="contact-phone"
              :description="$t('settings.profile.contact-phone-description')"
            >
              {{ $t('settings.profile.contact-phone') }}
            </rc-label>
            <vue-tel-input
              id="contact-phone"
              v-model="organizationData.phone"
              mode="international"
              :dropdown-options="{ showFlags: true, showDialCodeInSelection: false, showSearchBox: true }"
              :input-options="{ placeholder: '', showDialCode: false }"
              :valid-characters-only="true"
              @validate="handlePhoneNumberValidation"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small
              v-if="errors.length === 0 && !isPhoneNumberValid"
              class="text-danger"
            >{{ $t('validations.messages.phone-number-invalid') }}</small>
          </validation-provider>
        </div>

      </validation-observer>
    </rc-overlay>

    <wizard-footer
      is-first
      :disabled="processing || loading"
      @next="handleNext"
    />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import { computed, ref } from '@vue/composition-api'
import WizardFooter from '@/views/organization/wizard/WizardFooter'
import store from '@/store'
import login from '@/http/requests/auth/login'
import { parseRequestError } from '@/helpers/helpers'
import RcLabel from '@/layouts/components/rc/RcLabel'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import organization from '@/http/requests/organization'

export default {
  components: {
    WizardFooter, ValidationProvider, ValidationObserver, RcLabel, RcOverlay,
  },
  setup: (props, { emit }) => {
    const wizardStep1 = ref()
    const loading = ref(true)
    const processing = ref(false)
    const isPhoneNumberValid = ref(true)
    const organizationData = ref({})

    const me = computed(() => store.getters['auth/getUser'])

    login.userOrganizationInfo()
      .then(res => {
        organizationData.value = res.data
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        loading.value = false
      })

    const handlePhoneNumberValidation = phoneObject => {
      wizardStep1.value.reset()
      isPhoneNumberValid.value = phoneObject.valid === undefined || phoneObject.valid
    }

    const submit = () => {
      processing.value = true
      organization.patchOrganization(
        organizationData.value.id,
        organizationData.value,
      )
        .catch(err => {
          parseRequestError(err)
          emit('prev')
        })

        .finally(() => {
          processing.value = false
        })
    }

    const handleNext = () => {
      if (!isPhoneNumberValid.value) {
        return
      }

      wizardStep1.value.validate()
        .then(success => {
          if (success) {
            emit('next')
            submit()
          }
        })
    }

    return {
      me,
      wizardStep1,
      organizationData,
      isPhoneNumberValid,

      loading,
      processing,

      handleNext,
      handlePhoneNumberValidation,

      // validations
      required,
      email,
    }
  },
}
</script>
