<template>
  <div class="mx-1 mt-1">
    <h4 class="mb-50">
      {{ $t('wizard.step-2.title') }}
    </h4>
    <h5 class="text-muted mb-1">
      {{ $t('wizard.step-2.subtitle') }}
    </h5>

    <rc-overlay
      :show="loading"
      no-fade
      variant="white"
    >
      <validation-observer ref="wizardStep2">

        <address-form
          v-model="organizationData.address"
        />

        <b-form-checkbox
          v-model="differentBillingData"
          class="mt-2"
          checked="true"
          switch
          inline
        >
          {{ $t('wizard.step-2.different-billing-data') }}
        </b-form-checkbox>

        <div v-if="differentBillingData">
          <h5 class="mt-2 mb-1">
            {{ $t('wizard.step-2.billing-data') }}
          </h5>
          <address-form
            v-model="organizationData.billingAddress"
          />
        </div>

      </validation-observer>
    </rc-overlay>

    <wizard-footer
      :disabled="processing || loading"
      @next="handleNext"
      @prev="$emit('prev')"
    />
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { computed, ref } from '@vue/composition-api'
import WizardFooter from '@/views/organization/wizard/WizardFooter'
import store from '@/store'
import login from '@/http/requests/auth/login'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import organization from '@/http/requests/organization'
import AddressForm from '@/views/_global/AddressForm'

export default {
  components: {
    WizardFooter, ValidationObserver, RcOverlay, AddressForm,
  },
  setup: (props, { emit }) => {
    const wizardStep2 = ref()
    const loading = ref(true)
    const processing = ref(false)
    const differentBillingData = ref(false)
    const organizationData = ref({
      address: {
        companyName: null,
        addressLine1: null,
        zipCode: null,
        city: null,
        taxIdentifier: null,
      },
      billingAddress: {
        companyName: null,
        addressLine1: null,
        zipCode: null,
        city: null,
        taxIdentifier: null,
      },
    })

    const me = computed(() => store.getters['auth/getUser'])

    const diff = (address1, address2) => {
      if (address1.companyName !== address2.companyName) return false
      if (address1.addressLine1 !== address2.addressLine1) return false
      if (address1.zipCode !== address2.zipCode) return false
      if (address1.city !== address2.city) return false
      if (address1.taxIdentifier !== address2.taxIdentifier) return false
      return true
    }

    login.userOrganizationInfo()
      .then(res => {
        organizationData.value = res.data
        differentBillingData.value = !diff(res.data.address, res.data.billingAddress)
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        loading.value = false
      })

    const submit = () => {
      if (!differentBillingData.value) {
        organizationData.value.billingAddress = cloneNested(organizationData.value.address)
      }

      processing.value = true
      organization.patchOrganization(
        organizationData.value.id,
        organizationData.value,
      )
        .catch(err => {
          parseRequestError(err)
          emit('prev')
        })

        .finally(() => {
          processing.value = false
        })
    }

    const handleNext = () => {
      wizardStep2.value.validate()
        .then(success => {
          if (success) {
            emit('next')
            submit()
          }
        })
    }

    return {
      me,
      differentBillingData,
      wizardStep2,
      organizationData,

      loading,
      processing,

      handleNext,

      // validations
      required,
    }
  },
}
</script>
