<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-50"
    >
      <b-form-radio
        v-model="isRegisteredCompany"
        :value="true"
        class="d-md-inline mr-0 mr-md-2 mb-50"
      >
        {{ $t('settings.address-form.is-company') }}
      </b-form-radio>
      <b-form-radio
        v-model="isRegisteredCompany"
        :value="false"
        class="d-md-inline mr-0 mr-md-2 mb-50"
        @change="isRegisteredCompany === false ? value.taxIdentifier = null : null"
      >
        {{ $t('settings.address-form.is-not-company') }}
      </b-form-radio>
    </b-col>

    <b-col
      cols="12"
      class="mb-50"
    >
      <validation-provider
        #default="{ errors }"
        :name="isRegisteredCompany ? $t('settings.address-form.company-name') : $t('settings.address-form.name-and-surname')"
        rules="required|min:3|max:100"
      >
        <label for="name">
          {{ isRegisteredCompany ? $t('settings.address-form.company-name') : $t('settings.address-form.name-and-surname') }}
        </label>
        <b-form-input
          id="name"
          v-model="value.companyName"
          type="text"
          minlength="3"
          maxlength="100"
          :placeholder="isRegisteredCompany ? $t('settings.address-form.company-name') : $t('settings.address-form.name-and-surname')"
          autocomplete="chrome-off"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>

    <b-col
      cols="12"
      class="mb-50"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t('settings.address-form.street-number')"
        :rules="`${isRegisteredCompany ? 'required' : ''}|min:3|max:100`"
      >
        <label for="addressLine1">
          {{ $t('settings.address-form.street-number') }}
        </label>
        <b-form-input
          id="addressLine1"
          v-model="value.addressLine1"
          type="text"
          minlength="3"
          maxlength="100"
          :placeholder="$t('settings.address-form.street-number')"
          autocomplete="chrome-off"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>

    <b-col
      cols="12"
      class="mb-50"
    >
      <b-row>
        <b-col
          cols="5"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('settings.address-form.zip')"
            rules="required|min:3|max:10"
          >
            <label
              for="zip"
              class="text-nowrap"
            >
              {{ $t('settings.address-form.zip') }}
            </label>
            <b-form-input
              id="zip"
              v-model="value.zipCode"
              type="text"
              minlength="3"
              maxlength="10"
              :placeholder="$t('settings.address-form.zip')"
              autocomplete="chrome-off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col
          cols="7"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('settings.address-form.city')"
            rules="required|min:3|max:50"
          >
            <label for="city">
              {{ $t('settings.address-form.city') }}
            </label>
            <b-form-input
              id="city"
              v-model="value.city"
              type="text"
              minlength="3"
              maxlength="50"
              :placeholder="$t('settings.address-form.city')"
              autocomplete="chrome-off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    </b-col>

    <b-col
      v-if="isRegisteredCompany"
      cols="12"
      class="mb-50"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t('settings.address-form.tax-identifier')"
        rules="required"
      >
        <label for="taxIdentifier">
          {{ $t('settings.address-form.tax-identifier') }}
        </label>
        <b-form-input
          id="taxIdentifier"
          v-model="value.taxIdentifier"
          type="text"
          :placeholder="$t('settings.address-form.tax-identifier-placeholder')"
          autocomplete="chrome-off"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-col>
  </b-row>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'

export default {
  components: { ValidationProvider },
  props: {
    value: {
      type: Object,
      default: () => ({
        companyName: null,
        addressLine1: null,
        zipCode: null,
        city: null,
        taxIdentifier: null,
      }),
    },
  },
  setup: (props, { emit }) => {
    const isRegisteredCompany = ref(!!props.value.taxIdentifier)

    const updateValue = () => {
      emit('input', props.value)
    }

    watch(() => props.value, value => {
      isRegisteredCompany.value = !!value.taxIdentifier
    })

    return {
      isRegisteredCompany,

      updateValue,
    }
  },
}
</script>
