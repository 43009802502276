<template>
  <b-card-footer class="px-0 pb-0 mt-2">
    <b-row>
      <b-col
        cols="auto"
        class="mr-auto"
      >
        <b-button
          v-if="!isFirst"
          variant="flat-primary"
          :disabled="disabled"
          @click="$emit('prev')"
        >
          {{ $t('wizard.prev') }}
        </b-button>
      </b-col>

      <b-col
        cols="auto"
      >
        <b-button
          v-if="isLast"
          variant="primary"
          :disabled="disabled"
          @click="$emit('submit')"
        >
          {{ $t('wizard.submit') }}
        </b-button>
        <b-button
          v-else
          variant="primary"
          :disabled="disabled"
          @click="$emit('next')"
        >
          {{ $t('wizard.next') }}
        </b-button>
      </b-col>
    </b-row>

  </b-card-footer>
</template>
<script>
export default {
  props: {
    isLast: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
