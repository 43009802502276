<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="8"
        offset-sm="2"
        md="10"
        offset-md="1"
        lg="8"
        offset-lg="2"
        xl="6"
        offset-xl="3"
        class="mt-md-5 organization-wizard"
      >

        <b-card>
          <b-tabs
            v-model="currentTabIndex"
            vertical
            align="left"
          >
            <b-tab
              v-for="(step, index) in steps"
              :key="index"
              :title="step.name"
              :disabled="!step.enabled"
              no-nav-style
            >
              <template #title>
                <span class="text-left font-weight-bolder">
                  <b-avatar
                    :disabled="isDisabled(index)"
                    :variant="getAvatarBackground(index)"
                    rounded
                    :text="(index + 1).toString()"
                    class="mr-1"
                  />
                  {{ step.name }}
                </span>
              </template>

              <component
                :is="step.component"
                @prev="currentTabIndex--"
                @next="handleNext"
                @submit="handleSubmit"
              />

            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import '@core/scss/vue/libs/vue-wizard.scss'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@validations'
import { computed, nextTick, ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import WizardStep1 from '@/views/organization/wizard/WizardStep1'
import WizardStep2 from '@/views/organization/wizard/WizardStep2'
import WizardStep3 from '@/views/organization/wizard/WizardStep3'
import organization from '@/http/requests/organization'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    WizardStep1,
    WizardStep2,
    WizardStep3,
    ValidationProvider,
    ValidationObserver,

    FormWizard,
    TabContent,
  },
  data() {
    return {
      name: '',

      required,
      email,
    }
  },
  setup: () => {
    const me = computed(() => store.getters['auth/getUser'])
    const currentTabIndex = ref(1)
    const steps = ref([
      {
        name: i18n.t('wizard.step-1.title'),
        component: 'wizard-step-1',
        enabled: true,
      },
      {
        name: i18n.t('wizard.step-2.title'),
        component: 'wizard-step-2',
        enabled: false,
      },
      {
        name: i18n.t('wizard.step-3.title'),
        component: 'wizard-step-3',
        enabled: false,
      },
    ])

    const isDisabled = index => index > currentTabIndex.value
    const getAvatarBackground = index => {
      if (index === currentTabIndex.value) {
        return 'primary'
      }
      if (index > currentTabIndex.value) {
        return 'light-secondary'
      }
      return 'light-primary'
    }

    const handleNext = () => {
      steps.value[currentTabIndex.value + 1].enabled = true
      nextTick(() => {
        currentTabIndex.value += 1
      })
    }

    const handleSubmit = () => {
      const newMe = cloneNested(me.value)
      newMe.organization.profileComplete = true
      store.commit('auth/SET_USER_INFO', newMe)
      router.push({ name: 'dashboard-redirect' })

      organization.patchOrganization(
        me.value.organization.id,
        { profileComplete: true },
      )
        .catch(err => {
          parseRequestError(err)
        })
    }

    return {
      currentTabIndex,
      steps,

      isDisabled,
      getAvatarBackground,
      handleNext,
      handleSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables.scss';

.organization-wizard {
  .nav-tabs {
    //display: block;

    .nav-item {
      display: block;
      text-align: left;
    }
    .nav-link {
      display: block;
      padding-left: 0;
      &:after {
        content: none;
      }
    }
  }

  .tab-content {
    min-width: 300px;
    border-top: 1px solid #ddd;
  }
}

@media (min-width: 768px) {
  .organization-wizard {
    .tab-content {
      border-left: 1px solid #ddd;
      border-top: none;
    }
  }
}
</style>
