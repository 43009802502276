<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="hours"
          :clearable="false"
          :reduce="(option) => option.id"
          :options="selectHoursOptions"
          @option:selected="updateValue"
        />
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="minutes"
          :clearable="false"
          :reduce="(option) => option.id"
          :options="selectMinutesOptions"
          @option:selected="updateValue"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'DurationSelector',
  components: {
    BRow,
    BCol,
    vSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    hoursInterval: {
      type: Number,
      default: 1,
    },
    minutesInterval: {
      type: Number,
      default: 5,
    },
    maxHour: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      hours: this.toHours(this.value),
      minutes: this.toMinutes(this.value),
      selectOptions: {},
    }
  },
  computed: {
    model() {
      return (60 * Number(this.hours)) + Number(this.minutes)
    },
    selectHoursOptions() {
      const hours = []
      let lastValue = 0
      while (lastValue <= this.maxHour) {
        hours.push({ label: `${lastValue.toString()}h`, id: lastValue.toString() })
        lastValue += this.hoursInterval
      }
      return hours
    },
    selectMinutesOptions() {
      const minutes = []
      let lastValue = 0
      while (lastValue < 60) {
        minutes.push({ label: `${lastValue.toString()}min`, id: lastValue.toString() })
        lastValue += this.minutesInterval
      }
      return minutes
    },
  },
  watch: {
    value(newValue) {
      this.hours = this.toHours(newValue)
      this.minutes = this.toMinutes(newValue)
    },
  },
  methods: {
    toHours(val) {
      return Math.floor(val / 60).toString()
    },
    toMinutes(val) {
      return (val - Math.floor(val / 60) * 60).toString()
    },
    updateValue() {
      this.$emit('input', this.model)
    },
  },
}
</script>

<style lang="scss">
$vs-dropdown-max-height: 140px;
</style>
