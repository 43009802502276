<template>
  <span>
    <feather-icon
      v-if="description"
      :id="id"
      :icon="icon"
      class="text-muted"
    />
    <b-popover
      :target="id"
      triggers="hover"
    >
      <template #title>
        {{ title }}
      </template>
      <div v-html="description" />
    </b-popover>
  </span>
</template>
<script>
import _ from 'lodash'
import i18n from '@/libs/i18n'

export default {
  props: {
    title: {
      type: [String, Object],
      default: i18n.t('shared.pro-tip'),
    },
    description: {
      type: [String, Object],
      default: null,
    },
    icon: {
      type: String,
      default: 'HelpCircleIcon',
    },
  },
  data() {
    return {
      id: `rc-help-${_.uniqueId()}`,
    }
  },
}
</script>
