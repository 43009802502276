<template>
  <div class="mx-1 mt-1">
    <h4 class="mb-50">
      {{ $t('wizard.step-3.title') }}
    </h4>
    <h5 class="text-muted mb-1">
      {{ $t('wizard.step-3.subtitle') }}
    </h5>

    <rc-overlay
      :show="loading"
      no-fade
      variant="white"
    >
      <validation-observer ref="wizardStep3">

        <div class="mb-50">
          <validation-provider
            #default="{ errors }"
            :name="$t('services.form.base-information.name')"
            rules="required|min:2"
          >
            <label for="name">
              {{ $t('services.form.base-information.name') }}
            </label>
            <b-form-input
              id="name"
              v-model="service.name"
              type="text"
              minlength="2"
              maxlength="50"
              :placeholder="$t('services.form.base-information.name')"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="mb-50">
          <validation-provider
            #default="{ errors }"
            :name="$t('services.form.base-information.duration')"
            rules="required|min_value:5"
          >
            <label for="duration">
              {{ $t('services.form.base-information.duration') }}
            </label>
            <duration-selector
              id="duration"
              v-model="service.duration"
              :max-hour="6"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </div>

        <div class="mb-50">
          <b-row class="mb-50">
            <b-col
              cols="6"
              class="mb-50"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('services.form.base-information.price-gross')"
                rules="required"
              >
                <label for="priceGross">
                  {{ $t('services.form.base-information.price-gross') }}
                </label>
                <b-input-group
                  append="zł"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="priceGross"
                    v-model="service.priceGross"
                    type="number"
                    step="0.01"
                    value="0.00"
                    class="text-right"
                    placeholder="50.00"
                    autocomplete="off"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="6"
              class="mb-50"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('services.form.base-information.tax-percent')"
                rules="required"
              >
                <label for="taxPercent">
                  {{ $t('services.form.base-information.tax-percent') }}
                </label>
                <b-input-group
                  append="%"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="taxPercent"
                    v-model="service.taxPercent"
                    class="text-right"
                    type="number"
                    placeholder="23"
                    autocomplete="off"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="mb-50"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('services.form.base-information.price-type')"
                rules="required"
              >
                <label for="priceGross">
                  {{ $t('shared.price-types.title') }}
                </label>
                <v-select
                  id="selfReservationType"
                  v-model="service.priceType"
                  :options="priceTypes"
                  :clearable="false"
                  :searchable="false"
                  :reduce="(option) => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </rc-overlay>

    <wizard-footer
      is-last
      :disabled="processing || loading"
      @submit="handleSubmit"
      @prev="$emit('prev')"
    />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { computed, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { BCol } from 'bootstrap-vue'
import WizardFooter from '@/views/organization/wizard/WizardFooter'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import services from '@/http/requests/services'
import DurationSelector from '@/layouts/components/rc/DurationSelector'
import store from '@/store'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCol,
    vSelect,
    WizardFooter,
    ValidationObserver,
    ValidationProvider,
    RcOverlay,
    DurationSelector,
  },
  setup: (props, { emit }) => {
    const me = computed(() => store.getters['auth/getUser'])
    const wizardStep3 = ref()
    const loading = ref(true)
    const processing = ref(false)
    const service = ref({
      duration: 45,
      additionalTimeBefore: 0,
      additionalTimeAfter: 0,
      selfReservationType: 'unavailable',
      reservationInterval: 30,
      participantLimit: 1,
      color: '#3a859b',
      serviceLocation: ['online', 'at_the_teacher', 'at_the_customer'],
    })
    const priceTypes = ref([
      { id: 'PerMeeting', label: i18n.t('shared.price-types.PerMeeting') },
      { id: 'PerMonth', label: i18n.t('shared.price-types.PerMonth') },
      { id: 'PerCycle', label: i18n.t('shared.price-types.PerCycle') },
    ])

    services.getServices()
      .then(res => {
        if (res.data.length) {
          // eslint-disable-next-line prefer-destructuring
          service.value = res.data[0]
        }
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        loading.value = false
      })

    const sendRequest = () => {
      if (service.value.id) {
        return services.patchService(
          service.value.id,
          service.value,
        )
      }
      return services.postService(
        service.value,
      )
    }

    const addTestCustomer = () => {
      const testCustomer = cloneNested(store.state.customer.customerModel)

      testCustomer.firstName = me.value.firstName
      testCustomer.lastName = me.value.lastName
      testCustomer.email = me.value.email
      testCustomer.phone = me.value.phone
      testCustomer.notes = i18n.t('wizard.step-3.test-customer-note')

      return store.dispatch(
        'customer/addCustomer',
        {
          data: testCustomer,
        },
      )
        .catch()
    }

    const submit = () => {
      processing.value = true
      sendRequest()
        .then(() => {
          addTestCustomer()
          emit('submit')
        })
        .catch(err => {
          parseRequestError(err)
        })
        .finally(() => {
          processing.value = false
        })
    }

    const handleSubmit = () => {
      wizardStep3.value.validate()
        .then(success => {
          if (success) {
            submit()
          }
        })
    }

    return {
      me,
      wizardStep3,
      service,
      priceTypes,

      loading,
      processing,

      handleSubmit,

      // validations
      required,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
